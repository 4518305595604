import { Component } from 'react';
import './App.css';
import 'semantic-ui-css/semantic.min.css';
import Sidebar from './SideBar';
import { Card, Icon, Image } from 'semantic-ui-react'
import potraitImage from './Potrait.jpg'
import awsLogo from './AWS_Logo.png'
import { Button, Form, TextArea } from 'semantic-ui-react';
import { clarity } from 'react-microsoft-clarity';
import Footer from './Footer';

interface AppProps {
    // Define your props here
  }
  
  interface AppState {
    sideBarOpen: boolean,
    askaquestion: string
  }

class App extends Component<AppProps, AppState>  {
    constructor(props:AppProps) {
        super(props);
        this.state = {     
            sideBarOpen:false,
            askaquestion: ""
        };
    }

    sidebarOpen = (sideBarOpen: boolean) => {
        this.setState((prevState) => ({
            sideBarOpen:!prevState.sideBarOpen  
        }));
    };

    render () {
        const headerHTML: JSX.Element = (
            <header className="heading">
                <h1 className='heading-1'>Get in touch</h1>
                <h1 className='heading-2'>
                    <a href="mailto:devanshpgupta@outlook.com" target="_blank" rel="noopener noreferrer" title="devanshpgupta@outlook.com"><Icon name='mail' /> Email me </a>
                </h1>
                <h1 className='heading-3'>
                    <a href="https://www.linkedin.com/in/devanshpgupta" target="_blank" rel="noopener noreferrer" title="https://www.linkedin.com/in/devanshpgupta"><Icon name='linkedin' />Connect me</a>
                </h1> 
                <h1 className='heading-4'>
                    <a href="https://github.com/devanshpgupta" target="_blank" rel="noopener noreferrer" title="https://github.com/devanshpgupta"><Icon name='github' />GitHub</a>
                </h1> 
            </header>  
        )

        const summaryHTML:JSX.Element = (
            <Card>
            <Image src={potraitImage} wrapped ui={false} />
            <Card.Content>
              <Card.Header>Devansh Gupta</Card.Header>
              <Card.Meta>
                <span className='date' title='Website created date'>Created on Dec  17, 2023</span>
              </Card.Meta>
              <Card.Description>
                <div>
                    <p>
                    AWS Certified Solutions Architect with extensive experience in designing and implementing scalable, cost-efficient cloud solutions. Expertise in architecting optimized data infrastructures on AWS, enhancing system performance, and aligning solutions with business objectives. Proven track record in leading cross-functional teams to resolve complex data challenges and deliver impactful results, including up to 50% improvements in data processing and storage efficiency. Skilled in AWS services, data modeling, and automation strategies that drive operational excellence.   
                    </p>
                </div>
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
                <a href="mailto:devanshpgupta@outlook.com" title="devanshpgupta@outlook.com"> 
                    <Icon name='mail' />
                </a>

                <a href="https://www.linkedin.com/in/devanshpgupta" target="_blank" rel="noopener noreferrer" title="https://www.linkedin.com/in/devanshpgupta">
                    <Icon name='linkedin' />
                </a>
                
                <a href="https://github.com/devanshpgupta" target="_blank" rel="noopener noreferrer" title="https://github.com/devanshpgupta">
                    <Icon name='github' />
                </a>
            </Card.Content>
          </Card>
        )

        const educationHTML:JSX.Element = (
            <section className="education font-styling">
            <h2 className="header2-with-line">Education</h2>
            <p>
                <div className="header-container"> 
                    <h5 className="header5-with-line">
                        ● University of Massachusetts | Dartmouth, MA
                    </h5>
                    <h5 className="header5-with-line">
                        May 2017
                    </h5>
                </div> 
                <div className="header-container"> 
                    <p className="paragraph">
                        Completed Master of Science: Computer and Information Sciences
                    </p>
                    <p className="paragraph">
                        GPA: 3.4/4
                    </p>
                </div>
            </p>
            <p>
                <div className="header-container"> 
                    <h5 className="header5-with-line">
                        ● University of Pune | Pune, India
                    </h5>
                    <h5 className="header5-with-line">
                        June 2014
                    </h5>
                </div> 
                <div className="header-container"> 
                    <p className="paragraph">
                        Bachelor of Engineering: Computer Science
                    </p>
                    <p className="paragraph">
                        GPA: 3.7/4
                    </p>
                </div>
            </p>
            <p>
                <div className="header-container"> 
                    <h5 className="header5-with-line">
                        Relevant Courses Throughout:
                    </h5>
                    <p className="paragraph">
                        Data Structures & Algorithm, Advanced Databases, OOP, Compiler Design, Distributed OS, Computer Game Design, AI, Programming and Problem Solving, System programming and OS.
                    </p>
                </div> 
            </p>
          </section>
        )

        const skillsHTML: JSX.Element = (
            <section className="skills font-styling">
            <h2 className="header2-with-line">Technical Skills</h2>
            <table>
                {/* <colgroup>
                    <col style="width: 250px;"></col>
                    <col style="width: 550px;"></col>
                </colgroup> */}
                <tr className="table-row">
                  <th className="table-header">Programming Langs:</th>
                  <td className="table-cell">Java, Scala, Python, Javascript, Typescript, CPP, Scala, LUA, C</td>
                </tr>
                <tr className="table-row">
                  <th className="table-header">Web Technologies:</th>
                  <td className="table-cell">React, Redux, HTML, CSS, jQuery, Angular, Bootstrap, Ajax, JSP, Servlets</td>
                </tr>
                <tr className="table-row">
                  <th className="table-header">AWS Services:</th>
                  <td className="table-cell">S3, EMR, Athena, Step Functions, Lambda, AWS Glue, EC2, RDS, Database Migration Service, CloudWatch, AWS Lake Formation, Amazon SageMaker, Simple Queue Service, Elastic Container Service, Amazon EventBridge</td>
                </tr>
                <tr className="table-row">
                    <th className='table-header'>Frameworks & Tech:</th>
                    <td className="table-cell">Spark, Spring MVC, BOTO3, Maven, Spring Boot, Spring Transaction,
                        Spring Security, Hibernate Transaction Manager, Guava caching,
                        Quartz scheduler, VX Suite</td>
                  </tr>
                  <tr className="table-row">
                    <th className="table-header">Search Engine:</th>
                    <td className="table-cell">Elastic Search</td>
                  </tr>
                  <tr className="table-row">
                    <th className="table-header">Message Broker:</th>
                    <td className="table-cell">Apache Kafka, Apache ActiveMQ</td>
                  </tr>
                  <tr className="table-row">
                    <th className="table-header">ORM Software:</th>
                    <td className="table-cell">JDBC, JPA-Hibernate</td>
                  </tr>
                  <tr className="table-row">
                    <th className="table-header">Web Server:</th>
                    <td className="table-cell">NGINX</td>
                  </tr>
                  <tr className="table-row">
                    <th className="table-header">Cloud Services:</th>
                    <td className="table-cell">AWS, OCI</td>
                  </tr>
                  <tr className="table-row">
                    <th className="table-header">Devops orchestration:</th>
                    <td className="table-cell">Jenkins, Docker, Ansible</td>
                  </tr>
                  <tr className="table-row">
                    <th className="table-header">Database:</th>
                    <td className="table-cell">Oracle SQL, MySQL, SQL Server</td>
                  </tr>
                  <tr className="table-row">
                    <th className="table-header">Servers:</th>
                    <td className="table-cell">Apache Tomcat, JBOSS, WebSphere and WebLogic</td>
                  </tr>
                  <tr className="table-row">
                    <th className="table-header">IDEs:</th>
                    <td className="table-cell">Eclipse, JBOSS Dev Studio, Epic Unreal 4, Game Maker, Net Beans</td>
                  </tr>
                  <tr className="table-row">
                    <th className="table-header">Other Tools:</th>
                    <td className="table-cell">DBeaver, DBVisualizer, SQL Developer, Toad Data Modeler, Qlik Sense
                        + QMC</td>
                  </tr>
              </table>
        </section>
        )

        const experienceHTML: JSX.Element = (
            <section className="experience font-styling">
            <h2 className="header2-with-line">Professional Experience</h2>
            <div className="header-container"> 
                <h5 className="header5-with-line">
                    Principal Architect - Coditas Solutions LLP | Mumbai, India
                </h5>
                <h5 className="header5-with-line">
                    August 2023 - Till Date
                </h5>
            </div> 
            <p className="paragraph">
                <p><strong>Key Resposibilities:</strong></p>
                <ul>
                    <li>Collaborated with engineering, data science, and business analyst teams to analyze and fulfill data requirements, delivering integrated data solutions that enhanced system efficiency and workflow coherence for 3 major projects.</li>
                    <li>Led the data engineering team in adopting best practices for data management and coding standards, resulting in a 20% improvement in performance optimization across 4 key projects.</li>
                    <li>Implemented continuous monitoring and optimization strategies that improved data processing and storage efficiency by 30%, ensuring scalability for a 50% increase in data volume and complexity.</li>
                    <li>Solved critical data-related challenges, such as discrepancies and integration bottlenecks, by innovating solutions that improved data reliability and usability.</li>
                </ul>
                <p><strong>Professional Gatherings:</strong></p>
                <ul>
                    <li>Proven track record in software development and architecting complex systems.</li>
                    <li>Strong technical expertise in software design patterns, technologies, and methodologies.</li>
                    <li>Leadership abilities, driving teams towards achieving business-aligned technical goals.</li>
                </ul>
            </p>
            <div className="header-container"> 
                <h5 className="header5-with-line">
                    Senior Software Engineer - Coditas Solutions LLP | Mumbai, India
                </h5>
                <h5 className="header5-with-line">
                    May 2022 - July 2023
                </h5>
            </div> 
            <p className="paragraph">
                <ul>
                    <li>Designed and implemented scalable data models and architectures aligning with organizational objectives, enhancing data analytics capabilities, and efficiently managing large data volumes' ingestion, processing, and storage.</li>
                    <li>Developed and maintained robust data pipelines for ETL processes, improving data extraction from diverse sources to data lakes, which enhanced data quality and accessibility for analytics, supporting 3+ business intelligence initiatives.</li>
                    <li>Enhanced data warehouse modeling by designing efficient database schemas, facilitating improved data organization for querying and analysis, which streamlined reporting and decision-making processes for key stakeholders.</li>
                    <li>Fostered collaboration with Product Management, Customer Support, Stakeholders, and QA teams, translating business requirements into high-quality services and ensuring rigorous testing, significantly reducing deployment errors by 40%.</li>
                    <li>Authored and updated comprehensive technical documentation, covering data architectures and ETL processes, which improved team onboarding efficiency by 30% and facilitated better cross-functional understanding.</li>
                    Technical Skills: Amazon Web Services (AWS), Scala, Python, Apache Spark, Data Engineering, Terraform, Data Ingestion,Extract Transform Load (ETL), Data Modeling, Elastic Search
                </ul>
            </p>
  
            <div className="header-container"> 
                <h5 className="header5-with-line">
                    Software Engineer - Logicmanager, INC | Boston, MA, USA
                </h5>
                <h5 className="header5-with-line">
                    October 2019 - May 2022
                </h5>
            </div> 
            <p className="paragraph">
                <ul>
                    <li>Successfully implemented an ERM-based web framework, enhancing enterprise-level governance for a SaaS model, used by over 10,000 customers globally, demonstrating a risk-based approach.</li>
                    <li>Implemented RESTful services using Spring and React/Redux with Typescript, significantly improving data access/integration and state management, and enhancing system reliability and user experience.</li>
                    <li>Leveraged Jenkins and Nexus to streamline continuous integration, release processes, and production deployments, achieving a 40% reduction in deployment time and enhancing operational efficiency.</li>
                    <li>Fitted and deployed a Python-based data extraction model for contract scanning, customizing data transformation for 100+ contracts weekly, optimizing data processing efficiency by 30%.</li>
                    <li>Led code reviews, sprint demos, and backlog grooming, fostering an agile development environment that improved project delivery times by 25% and increased team productivity by 20%.</li>
                Technical Skills: JEE, Typescript, React, Redux, Spring, REST, Hibernate Transaction Management, Apache, Maven, Guava
                caching, Quartz scheduler, VX Suite, Oracle SQL, Jenkins, NGINX, Docker, Ansible, Oracle OCI.
                </ul>
            </p>
  
            <div className="header-container"> 
                <h5 className="header5-with-line">
                    Software Developer - United Health Group | Boston, MA, USA
                </h5>
                <h5 className="header5-with-line">
                    March 2018 – October 2019
                </h5>
            </div> 
            <p className="paragraph">
                <ul>
                    <li>Successfully implemented and deployed an Automation Framework, streamlining the creation, execution, and validation of tests, which enhanced testing efficiency by 40% and coverage by 30%.</li>
                    <li>Developed an ETL process using Apache Spark, processing over 1TB of real-time data daily from a source system, enhancing data accuracy and availability for analytics.</li>
                    <li>Implemented a RESTful API, facilitating seamless communication with multiple endpoints, which improved system integration and increased data throughput by 20%.</li>
                    <li>Crafted advanced SQL scripts, employing SQL Tuning and hierarchical queries, to generate critical reports, optimizing query performance by 50% and supporting strategic decision-making.</li>
                    Technical Skills: JEE, Apache Spark, Apache Kafka, Spring Boot, Hibernate, RedHat JBOSS, Maven, REST, SOAP UI, Oracle SQL,
                Jenkins, Docker, Batch Jobs, Qlik.
                </ul>
            </p>
  
            <div className="header-container"> 
                <h5 className="header5-with-line">
                    Software Engineer - Precision Technologies Corp. | Monmouth Junction, NJ, USA
                </h5>
                <h5 className="header5-with-line">
                    August 2017 – February 2018
                </h5>
            </div> 
            <p className="paragraph">
                <ul>
                    <li>Developed a web-tier application to automate job scheduling, executing files, scripts, and database procedures, coupled with an SFTP-based email distribution system, enhancing operational efficiency and reliability.</li>
                    <li>Crafted an intuitive user interface leveraging ECMAScript, jQuery, Angular 2, CSS, Bootstrap, and HTML5, significantly improving user experience and accessibility across platforms.</li>
                    Technical Skills: JEE, JPA, Spring, REST, Tibco MB, JBoss, JS, Bootstrap, CSS, jQuery, Angular2.
                </ul>
            </p>
  
            <div className="header-container"> 
                <h5 className="header5-with-line">
                    Software Engineer - Vartak Info Tech | Mumbai, India
                </h5>
                <h5 className="header5-with-line">
                    March 2014 – July 2015
                </h5>
            </div> 
            <p className="paragraph">
                <ul>
                    <li>Developed programs for accessing the database using JDBC thin drivers to execute CRUD operations, and stored procedures
                and to manipulate the data in the database. Developed JSPs, custom tags and implemented JS validation.</li>
                </ul>
            </p>
        </section>
        )

        const academicHTML: JSX.Element = (
            <section className="academic font-styling">
            <h2 className="header2-with-line">Academic Experience</h2>
            <h4 className='header4-with-line'>
                University of Massachusetts, Dartmouth, MA, USA
            </h4>
            <p>
                <div className="header-container"> 
                    <h5 className="header5-with-line">
                        Optimizing Better Computer Go Player with Neural Network and Long-Term Prediction.
                    </h5>
                    <h5 className="header5-with-line">
                        January 2017 – May 2017
                    </h5>
                </div> 
                <p className="paragraph">
                    <ul>
                        <li>Created a pure pattern-matching approach for Optimizing the GO game, based on a Deep Convolutional Neural Network
                    that predicts the next move and can even perform a Monte Carlo Tree Search (MCTS).</li>
                    </ul> 
                </p>
            </p>
            <p>
                <div className="header-container"> 
                    <h5 className="header5-with-line">
                        Game Programmer - First Person Shooter (Research Assistant)
                    </h5>
                    <h5 className="header5-with-line">
                        August 2016 – December 2016
                    </h5>
                </div> 
                <p className="paragraph">
                    <ul>
                        <li>Developed a fully functional 3D First Person Explorer game using Unreal Epic Engine 4.13</li>
                    </ul>
                </p>
            </p>
            <p>
                <div className="header-container"> 
                    <h5 className="header5-with-line">
                        Game Programmer - Excape
                    </h5>
                    <h5 className="header5-with-line">
                        October 2016 – December 2016
                    </h5>
                </div> 
                <p className="paragraph">
                    <ul>
                        <li>Created a First-person game by adding features like an AI Bot, the power to become invisible, or Pause all Bots.</li>
                    </ul>    
                    </p>
            </p>
            <p>
                <div className="header-container"> 
                    <h5 className="header5-with-line">
                        Cannibals and Missionaries Path Finding (Artificial Intelligence)
                    </h5>
                    <h5 className="header5-with-line">
                        October 2015 – December 2015
                    </h5>
                </div> 
                <p className="paragraph">
                    <ul>
                        <li>Created an application to find the optimal solution for sending Missionaries and cannibals from one side to another in
                    the least number of steps using Java.</li>
                    </ul>
                </p>
            </p>
        </section>
        )

        const certificationsHTML: JSX.Element = (
            <section className="experience font-styling">
            <h2 className="header2-with-line">Professional Certifications</h2>
            <div className="header-container">
                <h5>
                    <a 
                        href="https://www.credly.com/badges/97436fbc-eb86-4f5d-aff6-4ab2c1f9c23c/public_url" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        title="AWS Certificate - November 2024"
                    >
                        <Image src={awsLogo} size='small' alt="AWS Certified Solutions Architect - Associate" />
                    </a>
                </h5>
            </div> 
        </section>
        )
        
        const summaryText: String = this.state.sideBarOpen ? "hide" : ""
        // const selectFromMenuText: String = this.state.sideBarOpen ? "" : "hide"

        return (
            <div className='App'>
                <Sidebar
                    headerHTML={headerHTML}
                    summaryHTML={summaryHTML}
                    educationHTML={educationHTML}
                    skillsHTML={skillsHTML}
                    experienceHTML={experienceHTML}
                    academicHTML={academicHTML}
                    certificationsHTML={certificationsHTML}
                    sidebarOpen={this.sidebarOpen}
                />
                <div className={`homepage-summary ${summaryText}`}>
                    {/* <h1 className='homepage-header'>Hello People, I am Devansh Gupta</h1>
                    <h1 className='homepage-header'>A Solution Architect living in Mumbai, India</h1>
                    <h1 className='homepage-header'>with experience in Software development.</h1> */}
                    <h1 className='homepage-header'>The Product</h1>
                    <h1 className='homepage-header'>& Design Work of</h1>
                    <h1 className='homepage-header'>Devansh Gupta</h1> 
                </div>
                {/* <div className={`menu-expanded-text ${selectFromMenuText}`}>
                    <h1 className='homepage-expanded-text'>Explore by clicking on above Menu</h1>
                </div> */}
                <Footer />
            </div>
          );
    }
}

export default App;