// src/components/Footer.tsx
import React from 'react';

const Footer: React.FC = () => {
    return (
        <footer style={{ textAlign: 'center', padding: '1em', backgroundColor: '#f8f8f8' }}>
            <p>
                We improve our products and advertising by using Microsoft Clarity to see how you use our website.
                By using our site, you agree that we and Microsoft can collect and use this data. Our <a href="#privacy-policy">privacy statement</a> has more details.
            </p>
            <p>&copy; {new Date().getFullYear()} https://www.devanshpgupta.me. All Rights Reserved.</p>
        </footer>
    );
};

export default Footer;
